<template>
  <div class="card pt-2 border-light servicetypegroup-result">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-12 col-md-4 col-lg-2">
          <p>
            <label>{{ $t('nameESP') }}: </label>
            <span>{{ value.serviceTypeGroupNameESP }}</span>
          </p>
          <p>
            <label>{{ $t('nameENG') }}: </label>
            <span>{{ value.serviceTypeGroupNameENG || '-' }}</span>
          </p>
        </div>
        <div class="col col-sm-12 col-md-3 col-lg-8">
          <p>
            <b-form-checkbox
              v-model="value.isEnabled"
              class="chk-available"
              :disabled="true"
              switch
            >
              {{ $t('available') }}
            </b-form-checkbox>
          </p>
        </div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-2 pt-1">
          <action-button
            :actions="actions"
            class="float-right"
            :text="$t('actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'ServiceTypeGroupSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    displayOffice: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('addService'),
          iconClass: 'far fa-layer-group',
          iconColor: '#0F16DB',
          action: () => this.$emit('on-link'),
        },
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit', this.value),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.servicetypegroup-result p {
  margin-bottom: 0.2rem;
}

.servicetypegroup-result p i {
  cursor: pointer;
  font-size: 18px;
}

.servicetypegroup-result label {
  font-size: 14px;
  min-width: 100px;
  font-weight: bold;
}

.servicetypegroup-result p i,
.servicetypegroup-result span {
  color: $color-primary;
  font-weight: bold;
  margin-left: 5px;
}
</style>
