<template>
  <div class="mt-5 container-fluid">
    <div class="row">
      <content-header :title="$t('cloneService')" />
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <validation-observer ref="form" novalidate>
              <div class="row">
                <div v-if="!isMultiOffice" class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="model.companyId"
                      :clearable="false"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="text"
                      fieldvalue="value"
                      :label="$t('company')"
                      :options="filters.companies"
                    />
                  </validation-provider>
                </div>
                <div v-if="!isMultiOffice" class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="model.locationConfigurationId"
                      :clearable="false"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="name"
                      fieldvalue="id"
                      :label="$t('localization')"
                      :options="filters.locations"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:100"
                  >
                    <base-input
                      v-model="model.serviceTypeNameESP"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('serviceName')} ESP`"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="max:100">
                    <base-input
                      v-model="model.serviceTypeNameENG"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('serviceName')} ENG`"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="max:100">
                    <base-input
                      v-model="model.serviceTypeDescriptionESP"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('selfRegistrationServiceName')} ESP`"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="max:100">
                    <base-input
                      v-model="model.serviceTypeDescriptionENG"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('selfRegistrationServiceName')} ENG`"
                    />
                  </validation-provider>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  tag="div"
                  class="col-md-6"
                  rules="required|min_value: 0"
                >
                  <filter-numeric
                    v-model="model.autoRegisterOrder"
                    :clearable="false"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                    :min="0"
                    :title="$t('orderInSelfregistration')"
                  />
                </validation-provider>
                <div class="col-md-12">
                  <b-form-checkbox v-model="model.copyHours" switch>
                    {{ $t('copyBusinessHours') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="model.copyBusinessDays" switch>
                    {{ $t('copyWorkingDays') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="model.copyAttentionGoalTime" switch>
                    {{ $t('copyAttentionTargetTime') }}
                    {{
                      model.attentionGoalTime
                        ? ` - (${model.attentionGoalTime}) min`
                        : null
                    }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="model.copyWaitingGoalTime" switch>
                    {{ $t('copyGoalWaitTime') }}
                    {{
                      model.waitingGoalTime
                        ? ` - (${model.waitingGoalTime}) min`
                        : null
                    }}
                  </b-form-checkbox>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right mt-3">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $router.push({ name: 'ServicesList' })"
          :text="$t('cancel')"
        />

        <button class="btn btn-primary" @click="onSubmit()">
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IdentityPermission from '@/constants/IdentityPermission';
import BaseInput from '@/components/BaseInput.vue';
import ContentHeader from '@/components/Header';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import {
  getServiceType as _getServiceType,
  cloneServiceType as _cloneServiceType,
} from '@/services/ServicesService';

export default {
  name: 'ServiceClone',
  components: {
    BaseInput,
    ContentHeader,
  },
  data() {
    return {
      model: {
        serviceTypeId: null,
        companyId: null,
        locationConfigurationId: null,
        serviceTypeNameESP: null,
        serviceTypeNameENG: null,
        serviceTypeDescriptionESP: null,
        serviceTypeDescriptionENG: null,
        autoRegisterOrder: null,
        attentionGoalTime: null,
        waitingGoalTime: null,
        copyHours: true,
        copyBusinessDays: true,
        copyWaitingGoalTime: true,
        copyAttentionGoalTime: true,
      },
      initialized: false,
      filters: {
        companies: [],
        locations: [],
      },
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    'model.companyId'(value) {
      if (this.isMultiOffice) return;
      this.filters.locations = [];
      if (this.initialized) this.model.locationConfigurationId = null;
      if (!value) return;
      this.getLocations(value);
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;
      const model = {
        ...this.model,
        locationConfigurationId: this.isMultiOffice
          ? Number(this.currentActiveLocation.locationConfigurationId)
          : this.model.locationConfigurationId,
      };
      await _cloneServiceType(model).then(() => {
        this.ShowSuccessSaveToast();
        this.$router.push({ name: 'ServicesList' });
      });
    },
  },
  async mounted() {
    this.model.serviceTypeId = Number(this.$route.params.id);
    await _getServiceType(this.model.serviceTypeId).then(({ data }) => {
      this.model = {
        serviceTypeId: this.model.serviceTypeId,
        companyId: data.companyId,
        locationConfigurationId: data.locationConfigurationId,
        serviceTypeNameESP: data.serviceTypeNameESP
          ? `${data.serviceTypeNameESP} - copia`
          : null,
        serviceTypeNameENG: data.serviceTypeNameENG
          ? `${data.serviceTypeNameENG} - copy`
          : null,
        serviceTypeDescriptionESP: data.serviceTypeDescriptionESP
          ? `${data.serviceTypeDescriptionESP} - copia`
          : null,
        serviceTypeDescriptionENG: data.serviceTypeDescriptionENG
          ? `${data.serviceTypeDescriptionENG} - copy`
          : null,
        autoRegisterOrder: data.autoRegisterOrder,
        attentionGoalTime: data.attentionGoalTime,
        waitingGoalTime: data.waitingGoalTime,
        copyHours: true,
        copyBusinessDays: true,
        copyWaitingGoalTime: true,
        copyAttentionGoalTime: true,
      };
    });
    if (!this.isMultiOffice) await this.getCompanies();
    setTimeout(() => {
      this.initialized = true;
    }, 300);
  },
};
</script>
