<template>
  <section>
    <content-header v-if="showMaintenance" :title="$t('serviceMaintenance')" />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterServices')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="search(1)"
        />
      </div>
      <separator class="my-4" :text="$t('listOfServices')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => openFormModal()"
            :text="$t('create')"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(service, index) in services.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :display-office="!isMultiOffice"
            :value="service"
            @on-edit-business-hours="openBusinessHoursModal(service, $event)"
            @on-edit="openFormModal(service)"
            @on-delete="openDeleteModal(service)"
            @on-edit-business-days="openBusinessDaysModal(service)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="services.count > 0"
        id="pgPager"
        class="my-4"
        :data="services"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>
     <custom-modal
      v-model="showFormModal"
      :title="$t('serviceMaintenance')"
    >
      <ServiceForm
        v-if="showFormModal"
        :companies="filters.companies"
        :value="service"
        @close="showFormModal = false"
        @submitted="search()"
      />
    </custom-modal>
     <custom-modal
      v-model="showBusinessDaysModal"
      size="lg"
      :title="$t('businessDays')"
    >
      <BusinessDays
        v-if="showBusinessDaysModal"
        :id="service.serviceTypeId"
        @close="showBusinessDaysModal = false"
        @submitted="search()"
      />
    </custom-modal>
     <custom-modal
      v-model="showBusinessHoursModal"
      size="lg"
      :title="$t(isKioskBusinessHours ? 'businessHoursKiosk' : 'businessHours' )"
    >
      <BusinessHours
        v-if="showBusinessHoursModal"
        :kiosk-hours="isKioskBusinessHours"
        :value="businessHours"
        @close="showBusinessHoursModal = false"
        @submitted="search()"
      />
    </custom-modal>
    <BigLoader v-if="loading" />
    <AlertMessage
      v-if="!showMaintenance && !loading"
      :message="$t('selectOfficeForTheMaintenance')"
      style="margin-top: 150px"
    />
    <DeleteModal
      v-model="showDeleteModal"
      error-message="serviceNameIsNotCorrect"
      :item="service"
      label="serviceName"
      name="serviceTypeNameESP"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomModal from '@/components/basics/modal/CustomModal';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import BusinessDays from './components/BusinessDays';
import BusinessHours from './components/BusinessHours';
import SearchResult from './components/SearchResult';
import ServiceForm from './components/ServiceForm';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import Separator from '@/components/Separator';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList, getServiceQueues as _getServiceQueues } from '@/services/LocationService';
import { getServiceTypes as _getServiceTypes, deleteServiceType as _deleteServiceType } from '@/services/ServicesService';
import { modulesName } from '@/store';
import AlertMessage from '@/components/basics/alerts/AlertMessage';
import { mapGetters } from 'vuex';
import DeleteModal from '@/components/DeleteModal';

export default {
  name: 'RegisteredServices',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    AlertMessage,
    BigLoader,
    ServiceForm,
    CustomModal,
    BusinessDays,
    BusinessHours,
    DeleteModal
  },
  data() {
    return {
      filters: {
        locations: [],
        companies: [],
        rows: []
      },
      selectedFilters: {
        location: [],
        company: [],
        row: [],
        name: '',
      },
      services: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      businessHours: null,
      isKioskBusinessHours: false,
      showBusinessHoursModal: false,
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showMaintenance: false,
      showFormModal: false,
      showBusinessDaysModal: false,
      loading: false,
      service: null,
      showDeleteModal: false
    };
  },
  mounted() {
    if (!this.isMultiOffice) {
      this.getCompanies();
      this.showMaintenance = true;
    } else if (this.isMultiOffice && this.currentActiveLocation) {
      this.getServiceQueues(Number(this.currentActiveLocation.locationConfigurationId));
      this.showMaintenance = true;
    }
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    filtersConfig() {
      if (!this.isMultiOffice) {
        return [
          {
            rules: '',
            name: 'company',
            label: this.$t('company'),
            component: 'FilterMultiSelect',
            options: this.filters.companies,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'text',
            fieldvalue: 'value',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            error: this.$t('mustselectcompany'),
            initialValue: this.selectedFilters.company,
            onChanged: this.onCompanyChanged,
          },
          {
            rules: '',
            name: 'location',
            label: this.$t('localization'),
            component: 'FilterMultiSelect',
            options: this.filters.locations,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'name',
            fieldvalue: 'id',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.location,
            onChanged: this.onLocationChanged,
          },
          {
            rules: '',
            name: 'row',
            label: this.$t('row'),
            component: 'FilterMultiSelect',
            options: this.filters.rows,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'name',
            fieldvalue: 'id',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.row,
            onChanged: this.onRowChanged,
          },
          {
            rules: '',
            name: 'name',
            label: this.$t('name'),
            component: 'BaseInput',
            placeholder: this.$t('name'),
            fieldtext: 'name',
            fieldvalue: 'id',
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.name,
            value: this.selectedFilters.name,
          },
        ];
      }

      return [
        {
            rules: '',
            name: 'row',
            label: this.$t('row'),
            component: 'FilterMultiSelect',
            options: this.filters.rows,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'name',
            fieldvalue: 'id',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.row,
            onChanged: this.onRowChanged,
          },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    currentActiveLocation() {
      this.showMaintenance = false;
      this.loading = true;
      this.noResultsFound = false;
      setTimeout(() => {
        this.selectedFilters.name = '';
        this.services = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
        this.showMaintenance = true;
      }, 1200);
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getServiceQueues(locationConfigurationId) {
      _getServiceQueues(locationConfigurationId)
        .then((response) => {
          this.filters.rows = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.selectedFilters.company = [];
      this.selectedFilters.row = [];
      this.filters.locations = [];
      this.filters.rows = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getLocations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      this.selectedFilters.row = [];
      this.filters.rows = [];
      if (!value) return (this.selectedFilters.location = []);

      this.selectedFilters.location = [value];
      this.getServiceQueues(value.id)
    },
    onRowChanged(value) {
      if (!value) return (this.selectedFilters.row = []);

      this.selectedFilters.row = [value];
    },
    async search(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        companyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        locationConfigurationId: this.isMultiOffice
          ? Number(this.currentActiveLocation.locationConfigurationId)
          : this.selectedFilters.location.length > 0
          ? this.selectedFilters.location[0].id
          : null,
          serviceQueueId:
          this.selectedFilters.row.length > 0
            ? this.selectedFilters.row[0].id
            : null,
        serviceTypeSearchText: this.selectedFilters.name || null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getServiceTypes(payload)
        .then(({ data }) => {
          this.services = data;
          this.noResultsFound = !this.services.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openFormModal(service = null) {
      this.service = service;
      this.showFormModal = true;
    },
    openBusinessDaysModal(service) {
      this.service = service;
      this.showBusinessDaysModal = true;
    },
    openBusinessHoursModal(service, isKioskBusinessHours) {
      this.isKioskBusinessHours = isKioskBusinessHours;
      this.businessHours = {
        serviceTypeId: service.serviceTypeId,
        hourFrom: service[isKioskBusinessHours ? 'hourFromAutoRegister' : 'hourFrom'],
        hourTo: service[isKioskBusinessHours ? 'hourToAutoRegister' : 'hourTo']
      }
      this.showBusinessHoursModal = true;
    },
    openDeleteModal(data) {
      this.showDeleteModal = true;
      this.service = data;
    },
    onDelete() {
      _deleteServiceType(this.service.serviceTypeId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.search();
          this.service = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
