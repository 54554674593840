<template>
  <section>
    <content-header
      v-if="showMaintenance"
      :title="$t('serviceTypeGroupMaintenance')"
    />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterServiceTypeGroups')" />
        </div>
        <validation-observer ref="serviceTypeGroupFormRef" novalidate>
          <filter-manager
            ref="filterComponent"
            v-model="selectedFilters"
            class="mb-4"
            :display-advanced="false"
            :filters="filtersConfig"
            :search-text="$t('filter')"
            @search="search(1)"
          />
        </validation-observer>
      </div>

      <separator class="my-4" :text="$t('listOfGroups')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="openCreateModal"
            :text="$t('create')"
            :disabled="!companyIdForEditing"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(serviceTypeGroup, index) in serviceTypeGroups.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :display-office="!isMultiOffice"
            :value="serviceTypeGroup"
            @on-edit="openEditModal(serviceTypeGroup)"
            @on-delete="openDeleteModal(serviceTypeGroup)"
            @on-link="openLinkServiceModal(serviceTypeGroup)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="serviceTypeGroups.count > 0"
        id="pgPager"
        class="my-4"
        :data="serviceTypeGroups"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showServiceTypeGroupFormModal"
      size="md"
      :title="!editModel ? $t('createServiceTypeGroup') : $t('editServiceTypeGroup')"
    >
      <service-type-group-form
        v-if="showServiceTypeGroupFormModal"
        v-model="editModel"
        :company-id="companyIdForEditing"
        @close="showServiceTypeGroupFormModal = false"
        @submitted="search()"
      />
    </custom-modal>
    <custom-modal
      v-model="showServiceGroupLinkedServicesModal"
      size="md"
      :title="$t('serviceSelectionAccordingToOffice')"
    >
      <service-type-group-linked-services
        v-if="showServiceGroupLinkedServicesModal"
        v-model="linkedServicesModel"
        @close="showServiceGroupLinkedServicesModal = false"
      />
    </custom-modal>

    <BigLoader v-if="loading" />
    <AlertMessage
      v-if="!showMaintenance && !loading"
      :message="$t('selectOfficeForTheMaintenance')"
      style="margin-top: 150px"
    />
    <DeleteModal
      v-model="showDeleteModal"
      error-message="groupNameIsNotCorrect"
      :item="editModel"
      label="serviceTypeGroupName"
      name="serviceTypeGroupNameESP"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomModal from '@/components/basics/modal/CustomModal';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import Separator from '@/components/Separator';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { 
  getServiceTypeGroups as _getServiceTypeGroups, 
  deleteServiceTypeGroup as _deleteServiceTypeGroup 
  } from '@/services/ServicesService';
import { modulesName } from '@/store';
import AlertMessage from '@/components/basics/alerts/AlertMessage';

import ServiceTypeGroupForm from './components/ServiceTypeGroupForm';
import ServiceTypeGroupLinkedServices from './components/ServiceTypeGroupLinkedServices';

import DeleteModal from '@/components/DeleteModal';

import { mapGetters } from 'vuex';

export default {
  name: 'RegisteredServiceTypeGroups',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    AlertMessage,
    BigLoader,
    ServiceTypeGroupForm,
    ServiceTypeGroupLinkedServices,
    CustomModal,
    DeleteModal
  },
  data() {
    return {
      filters: {
        companies: [],
      },
      selectedFilters: {
        company: [],
        name: '',
      },
      serviceTypeGroups: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      selectedServiceTypeGroup: null,
      showMaintenance: false,

      showServiceTypeGroupFormModal: false,
      showServiceGroupLinkedServicesModal: false,
      showDeleteModal: false,
      loading: false,

      companyIdForEditing: null,
      editModel: null,

      linkedServicesModel: {
        companyId: null,
        serviceTypeGroupId: null
      }
    };
  },
  mounted() {
    this.getCompanies();
    this.showMaintenance = true;
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    filtersConfig() {
      return [
        {
          rules: 'required',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('select')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
    locationConfigurationId() {
      return this.isMultiOffice
        ? Number(this.currentActiveLocation.locationConfigurationId)
        : this.selectedFilters.location.length > 0
        ? this.selectedFilters.location[0].id
        : null;
    },
  },
  watch: {
    currentActiveLocation() {
      this.showMaintenance = false;
      this.loading = true;
      this.noResultsFound = false;
      setTimeout(() => {
        this.selectedFilters.company = null;
        this.selectedFilters.name = '';
        this.serviceTypeGroups = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
        this.showMaintenance = true;
      }, 1200);
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.company = [];
      this.companyIdForEditing = null;

      if (value) {
        this.selectedFilters.company = [value];
        this.companyIdForEditing = value.value;
      }
    },
    openCreateModal() {
      this.showServiceTypeGroupFormModal = true;
      this.editModel = null;
    },
    openDeleteModal(data) {
      this.showDeleteModal = true;
      this.editModel = { ...data };
    },
    openLinkServiceModal(data) {
      this.linkedServicesModel = { 
        companyId: data.companyId,
        serviceTypeGroupId: data.serviceTypeGroupId
      };

      this.showServiceGroupLinkedServicesModal = true;
    },
    openEditModal(data) {
      this.showServiceTypeGroupFormModal = true;
      this.editModel = { ...data };
    },
    onDelete() {
      _deleteServiceTypeGroup(this.editModel.serviceTypeGroupId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.search();
          this.editModel = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async search(pageIndex = null, pageSize = null) {
      const isValid = await this.$refs.serviceTypeGroupFormRef.validate();

      if (!isValid) {
        return;
      }

      this.companyIdForEditing = this.selectedFilters.company[0].value;

      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        companyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        serviceTypeGroupName: this.selectedFilters.name || null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getServiceTypeGroups(payload)
        .then(({ data }) => {
          this.serviceTypeGroups = data;
          this.noResultsFound = !this.serviceTypeGroups.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
