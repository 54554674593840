<template>
  <div class="col-md-12">
    <div class="row my-3">
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleSunday" >
          {{ $t('sunday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleMonday" >
          {{ $t('monday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleTuesday" >
          {{ $t('tuesday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleWednesday" >
          {{ $t('wednesday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleThursday" >
          {{ $t('thursday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleFriday" >
          {{ $t('friday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleSaturday" >
          {{ $t('saturday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-12 text-right mt-3">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $emit('close')"
        :text="$t('cancel')"
      />

      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
    </div>

  </div>
</template>

<script>
import {
  getServiceTypesBusinessDays as _getServiceTypesBusinessDays,
  updateServiceTypesBusinessDays as _updateServiceTypesBusinessDays,
} from '@/services/ServicesService';

export default {
  name: 'BusinessDays',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      model: {
        isVisibleMonday: false,
        isVisibleTuesday: false,
        isVisibleWednesday: false,
        isVisibleThursday: false,
        isVisibleFriday: false,
        isVisibleSaturday: false,
        isVisibleSunday: false,
      },
    };
  },
  mounted() {
    this.getBusinessDays(this.id);
  },
  methods: {
    async getBusinessDays(id) {
      await _getServiceTypesBusinessDays(id)
        .then((response) => (this.model = {...this.model, ...response.data}))
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      _updateServiceTypesBusinessDays({
        ...this.model,
        serviceTypeId: this.id,
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
  },
};
</script>



