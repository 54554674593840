<template>
  <div class="col-md-12">
    <validation-observer ref="formRef" novalidate>
      <div class="row">
        <div v-if="!isMultiOffice" class="col-md-6">
          <validation-provider v-slot="{ errors }" rules="required">
            <filter-select
              v-model="model.companyId"
              :clearable="false"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('company')"
              :options="companies"
            />
          </validation-provider>
        </div>
        <div v-if="!isMultiOffice" class="col-md-6">
          <validation-provider v-slot="{ errors }" rules="required">
            <filter-select
              v-model="model.locationConfigurationId"
              :clearable="false"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('localization')"
              :options="filters.locations"
            />
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider v-slot="{ errors }" rules="required|max:100">
            <base-input
              v-model="model.serviceTypeNameESP"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="`${$t('serviceName')} ESP`"
            />
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider v-slot="{ errors }" rules="max:100">
            <base-input
              v-model="model.serviceTypeNameENG"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="`${$t('serviceName')} ENG`"
            />
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider v-slot="{ errors }" rules="max:100">
            <base-input
              v-model="model.serviceTypeDescriptionESP"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="`${$t('selfRegistrationServiceName')} ESP`"
            />
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider v-slot="{ errors }" rules="max:100">
            <base-input
              v-model="model.serviceTypeDescriptionENG"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="`${$t('selfRegistrationServiceName')} ENG`"
            />
          </validation-provider>
        </div>
        <validation-provider
          v-slot="{ errors }"
          class="col-md-6"
          rules="min_value: 0"
          tag="div"
        >
          <filter-numeric
            v-model="model.waitingGoalTime"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :min="0"
            :title="$t('waitTimeInMinuts')"
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          class="col-md-6"
          rules="min_value: 0"
          tag="div"
        >
          <filter-numeric
            v-model="model.attentionGoalTime"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :min="0"
            :title="$t('targetAttentionTime')"
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          class="col-md-6"
          rules="required|min_value: 0"
          tag="div"
        >
          <filter-numeric
            v-model="model.autoRegisterOrder"
            :clearable="false"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :min="0"
            :title="$t('orderInSelfregistration')"
          />
        </validation-provider>
        <div class="col-md-6">
          <validation-provider v-slot="{ errors }" rules="max:2">
            <base-input
              v-model="model.specialField"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="$t('specialField')"
            />
          </validation-provider>
        </div>
        <validation-provider
          v-slot="{ errors }"
          class="col-md-6"
          rules="min_value: 0"
          tag="div"
        >
          <filter-numeric
            v-model="model.priorityInTurn"
            :clearable="false"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :min="0"
            :title="$t('turnPriority')"
          />
        </validation-provider>
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <b-form-checkbox v-model="model.isCommentRequired" switch>
            {{ $t('commentIsRequired') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-6">
          <b-form-checkbox v-model="model.isAvailable" switch>
            {{ $t('available') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-12 text-right mt-3">
          <base-filled-button
            bg-color="#707070"
            class="mx-0 float-left"
            icon-class="fas fa-ban"
            :on-click="() => $emit('close')"
            :text="$t('cancel')"
          />

          <button
            class="btn btn-outline-primary"
            variant="outline-primary"
            @click="onSubmit()"
          >
            <i class="far fa-save" /> {{ $t('save') }}
          </button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import IdentityPermission from '@/constants/IdentityPermission';
import BaseInput from '@/components/BaseInput.vue';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import {
  createServiceType as _createServiceType,
  updateServiceType as _updateServiceType,
} from '@/services/ServicesService';

export default {
  name: 'ServiceForm',
  components: {
    BaseInput,
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        companyId: null,
        locationConfigurationId: null,
        serviceTypeId: null,
        serviceTypeNameESP: null,
        serviceTypeNameENG: null,
        serviceTypeDescriptionESP: null,
        serviceTypeDescriptionENG: null,
        waitingGoalTime: null,
        attentionGoalTime: null,
        autoRegisterOrder: null,
        specialField: null,
        isCommentRequired: false,
        isAvailable: true,
        priorityInTurn: null
      },
      initialized: false,
      filters: {
        locations: [],
      },
    };
  },
  mounted() {
    if (this.value && this.value.serviceTypeId) this.model = { ...this.value };
    setTimeout(() => {
      this.initialized = true;
    }, 300);
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    'model.companyId'(value) {
      this.filters.locations = [];
      if (this.initialized) this.model.locationConfigurationId = null;
      if (!value) return;
      this.getLocations(value);
    },
  },
  methods: {
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;
      const model = {
        ...this.model,
        locationConfigurationId: this.isMultiOffice
          ? Number(this.currentActiveLocation.locationConfigurationId)
          : this.model.locationConfigurationId,
      };
      if (!this.model.serviceTypeId) {
        await _createServiceType(model).then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        });
      } else {
        await _updateServiceType(model).then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff8105 !important;
  background-color: #ff8105 !important;
}
</style>
