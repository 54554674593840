<template>
  <div class="card pt-2 border-light servicetype-result">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-6 col-md-6 col-lg-3">
          <p v-if="!isMultiOffice">
            <label>{{ $t('company') }}: </label>
            <span>{{ value.companyName || '-' }}</span>
          </p>
          <p v-if="!isMultiOffice">
            <label>{{ $t('location') }}: </label>
            <span>{{ value.locationName || '-' }}</span>
          </p>
          <p>
            <label>{{ $t('row') }}: </label>
            <span>{{ value.serviceQueueName || '-' }}</span>
          </p>
          <p>
            <label>{{ $t('nameOfServiceESP') }}: </label>
            <span>{{ value.serviceTypeNameESP }}</span>
          </p>
          <p>
            <label>{{ $t('nameOfServiceENG') }}: </label>
            <span>{{ value.serviceTypeNameENG || '-' }}</span>
          </p>
          <p>
            <label>{{ $t('autoregisterNameOfServiceESP') }}: </label>
            <span>{{ value.serviceTypeDescriptionESP || '-' }}</span>
          </p>
          <p>
            <label>{{ $t('autoregisterNameOfServiceENG') }}: </label>
            <span>{{ value.serviceTypeDescriptionENG || '-' }}</span>
          </p>
        </div>

        <div class="col col-sm-6 col-md-6 col-lg-3">
          <p>
            <label>{{ $t('waitingTimeInMinutes') }}: </label>
            <span>{{ value.waitingGoalTime }}</span>
          </p>
          <p>
            <label>{{ $t('attentionGoalTime') }}: </label>
            <span>{{ value.attentionGoalTime }}</span>
          </p>
          <p>
            <label>{{ $t('autoregistrationOrder') }}: </label>
            <span>{{ value.autoRegisterOrder }}</span>
          </p>
          <p>
            <label>{{ $t('specialField') }}: </label>
            <span>{{ value.specialField || '-' }}</span>
          </p>
        <p>
            <label>{{ $t('isCommentRequired') }}: </label>
            <span>{{ $t(value.isCommentRequired ? 'yes' : 'no' ) }}</span>
          </p>
        </div>

        <div class="col col-sm-6 col-md-6 col-lg-3">
          <p>
            <label>{{ $t('availableHoursForService') }}: </label>
            <i class="far fa-edit" @click="$emit('on-edit-business-hours', false)" />
          </p>
          <p>
            <label>{{ $t('kioskHoursForService') }}: </label>
            <i class="far fa-edit"  @click="$emit('on-edit-business-hours', true)" />
          </p>
          <p>
            <label>{{ $t('availableDaysForService') }}: </label>
            <i class="far fa-edit" @click="$emit('on-edit-business-days')" />
          </p>
          <p>
            <label>{{ $t('available') }}: </label>
            <span>{{ $t(value.isAvailable ? 'yes' : 'no' ) }}</span>
          </p>
          <p>
            <label>{{ $t('turnPriority') }}: </label>
            <span>{{ value.priorityInTurn }}</span>
          </p>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-2 pt-4">
          <action-button
            :actions="actions"
            class="float-right"
            :text="$t('actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import IdentityPermission from '@/constants/IdentityPermission';

export default {
  name: 'ServiceQueueSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    displayOffice: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit', this.value),
        },
        {
          label: this.$t('clone'),
          iconClass: 'far fa-clone',
          iconColor: '#198E0F',
          action: () => this.$router.push({name: 'ServicesClone', params: {id: this.value.serviceTypeId}}),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
  computed: {
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
};
</script>

<style lang="scss" scoped>
.servicetype-result p {
  margin-bottom: 0.2rem;
}

.servicetype-result p i {
  cursor: pointer;
  font-size: 18px;
}

.servicetype-result label {
  font-size: 14px;
  min-width: 70px;
  font-weight: bold;
}

.servicetype-result p i,
.servicetype-result span {
  color: $color-primary;
  font-weight: bold;
  margin-left: 5px;
}

.servicetype-result .custom-control.custom-switch {
  display: inline;
  margin-left: 5px;
}
</style>
