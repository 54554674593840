<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <label class="mb-0" for="hourFrom">{{ $t('hourFrom') }}: </label>
        <base-datetime-picker
          id="hourFrom"
          class="theme-orange"
          icon-class="clock"
          type="time"
          :value="value.hourFrom"
          @change="value.hourFrom = $event"
        />
      </div>
      <div class="col-md-12">
        <label class="mb-0" for="hourTo">{{ $t('hourTo') }}: </label>
        <base-datetime-picker
          id="hourTo"
          class="theme-orange"
          icon-class="clock"
          type="time"
          :value="value.hourTo"
          @change="value.hourTo = $event"
        />
      </div>
      <div class="col-md-12 text-right mt-3">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <button
          class="btn btn-outline-primary"
          variant="outline-primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  updateServiceTypesKioskHours as _updateServiceTypesKioskHours,
  updateServiceTypesHours as _updateServiceTypesHours,
} from '@/services/ServicesService';
import moment from 'moment';

export default {
  name: 'BusinessDays',
  props: {
    kioskHours: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async onSubmit() {
      const model = {
        ...this.value,
        hourFrom: this.value.hourFrom
          ? moment(this.value.hourFrom).format('LTS')
          : null,
        hourTo: this.value.hourTo
          ? moment(this.value.hourTo).format('LTS')
          : null,
      };
      if (this.kioskHours) {
        _updateServiceTypesKioskHours(model).then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        });
      } else {
        _updateServiceTypesHours(model).then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        });
      }
    },
  },
};
</script>

